<template>
  <div
    class="flex w-fit gap-2 group items-center text-txt-weak cursor-pointer"
    @click="goBack"
  >
    <WebccIcon
      name="site/chevron-left"
      class="w-5 h-5 group-hover:-translate-x-2 transition-all"
    />
    <span>
      {{ $t(`${trPrefix}back`) }}
    </span>
  </div>
</template>

<script lang="ts" setup>
const trPrefix = 'guestWorld.components.auth.StepBackButton.'

interface Props {
  isLink?: boolean
  link?: string
  step?: RegisterStep
  external?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isLink: false,
  link: '',
  step: 'email',
  external: false,
})

// Emits the back button click and the form password submission
const emit = defineEmits<{
  back: [step: RegisterStep]
}>()

// Go back to the previous step
async function goBack() {
  // Check if the step is a link or a step
  if (props.isLink) {
    // Redirect to the link
    return await navigateTo(
      { path: props.link, query: useRoute().query },
      { external: props.external },
    )
  }

  // No link, emit the back event
  emit('back', props.step)
}
</script>

<style></style>
